/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/css/all.css';

/* width */
::-webkit-scrollbar {
	width: 8px !important;
	height: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
	background: #efefef !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #c5c5c5 !important;
	border-radius: 5px !important;
	border-width: 5px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #a3a3a3 !important;
}

// Font size
body {
	font-size: 0.8125rem !important;

	fuse-splash-screen img {
		width: 200px !important;
		max-width: 200px !important;
	}
}

// Drawer content
[cdkscrollable] {
	display: flex !important;
}

// Dialog
.mat-mdc-menu-content {
	padding: 0 !important;
}

// Navigation alignment
.fuse-vertical-navigation,
fuse-vertical-navigation-basic-item,
fuse-vertical-navigation-collapsable-item {
	margin-top: 8px !important;
	margin-bottom: -4px !important;
}

fuse-vertical-navigation {
	--fuse-vertical-navigation-width: 235px !important;
}

.align-middle {
	vertical-align: middle !important;
}

// Snackbar
.mat-mdc-dialog-container .mdc-dialog__surface,
.mdc-snackbar__label,
.mdc-snackbar__surface {
	padding: 0 !important;

	&.mdc-dialog__surface {
		min-height: 0 !important;
	}
}

// Hide inputs errors container
.fuse-mat-no-subscript {
	.mat-mdc-form-field-subscript-wrapper {
		height: 0 !important;
	}
}

// Increase mat error space
.mat-error-large-text {
	.mat-mdc-form-field-subscript-wrapper {
		position: static !important;

		.mat-mdc-form-field-error-wrapper {
			position: relative !important;
			bottom: 1rem !important;
			top: auto !important;
			left: 0.2rem !important;
			text-indent: -0.2rem !important;
		}
	}
}

// Checkbox
.mdc-checkbox:not(.checkbox-xl) {
	flex: 0 0 6px !important;
	width: 6px !important;
	height: 6px !important;
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true']) ~ .mdc-checkbox__background {
	top: 5px !important;
	left: 5px !important;
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate='true']:enabled ~ .mdc-checkbox__background {
	top: 5px !important;
	left: 5px !important;
}

.mat-mdc-checkbox-touch-target:not(.checkbox-xl) {
	height: 20px !important;
	width: 20px !important;
}

// Slide
.mat-mdc-slide-toggle:not(.slide-xl) {
	--mdc-switch-state-layer-size: 30px !important;

	.mdc-switch {
		.mdc-switch__track {
			height: var(--mdc-switch-track-height, 10px) !important;
		}

		.mdc-switch__handle {
			width: var(--mdc-switch-handle-width, 18px) !important;
			height: var(--mdc-switch-handle-height, 18px) !important;
		}

		.mdc-switch__ripple {
			height: var(--mdc-switch-state-layer-size, 30px) !important;
			width: var(--mdc-switch-state-layer-size, 30px) !important;
		}

		&.mdc-switch--selected .mdc-switch__icon {
			width: var(--mdc-switch-selected-icon-size, 16px) !important;
			height: var(--mdc-switch-selected-icon-size, 16px) !important;
		}
	}
}

// Form fields
.mat-mdc-form-field.mat-form-field-appearance-fill:not(.form-field-xl) {
	.mat-mdc-text-field-wrapper:not(.mdc-text-field--no-label) {
		margin-top: 24px !important;
	}

	.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
		padding: 0 10px !important;

		.mat-mdc-form-field-infix {
			min-height: 32px !important;
		}

		.mat-mdc-form-field-icon-prefix > .mat-icon,
		.mat-mdc-form-field-text-prefix > .mat-icon {
			margin-left: -4px !important;
			margin-right: 4px !important;
		}
	}
}

// Select fields
.mdc-menu-surface.mat-mdc-select-panel {
	padding: 0 !important;
}

.mat-mdc-option:not(.option-xl) {
	padding-left: 12px !important;
	padding-right: 12px !important;
	min-height: 32px !important;
}

// Paginator
.mat-mdc-paginator-container {
	padding: 0 16px !important;
	justify-content: right !important;
	min-height: 40px !important;
}

.mat-mdc-paginator {
	.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix,
	.mat-mdc-form-field-infix {
		padding-top: 2px !important;
		padding-bottom: 2px !important;
		min-height: 28px !important;
	}

	.mat-mdc-paginator-container .mat-mdc-paginator-page-size {
		margin: 0 !important;
	}
}

// Buttons
button:not(.button-xl) {
	&[mat-icon-button]:not(.mat-calendar-previous-button):not(.mat-calendar-next-button) {
		width: 28px !important;
		height: 28px !important;
		min-width: 28px !important;
		min-height: 28px !important;
	}

	&[mat-stroked-button] {
		max-height: 30px !important;
		min-height: 30px !important;
	}
}

.mat-mdc-button:not(.button-xl),
.mat-mdc-raised-button:not(.button-xl),
.mat-mdc-outlined-button:not(.button-xl),
.mat-mdc-unelevated-button:not(.button-xl),
.mat-mdc-fab:not(.button-xl),
.mat-mdc-mini-fab:not(.button-xl) {
	height: 30px !important;
	min-height: 30px !important;
	max-height: 30px !important;
	padding: 0 12px !important;
}

.mat-mdc-icon-button:not(.mat-calendar-previous-button):not(.mat-calendar-next-button):not(.mat-mdc-paginator-navigation-first) .mat-mdc-button-touch-target {
	height: 28px !important;
	width: 28px !important;
	min-height: 28px !important;
	max-height: 28px !important;
}

// Remove number input arrows
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none !important;
	margin: 0 !important;
}

// Tooltip
.mat-mdc-tooltip .mdc-tooltip__surface {
	max-width: 24rem !important;
	background-color: #2a292a !important;
	color: #efefef !important;
}

// Chips
.mat-mdc-chip {
	font-size: 0.8125rem;
}

.mat-mdc-standard-chip .mdc-evolution-chip__text-label {
	font-size: inherit !important;
}

.mdc-evolution-chip-set__chips {
	max-width: 100% !important;
}

.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip .mat-mdc-chip-action-label {
	overflow: hidden !important;
}

.mdc-evolution-chip__text-label {
	user-select: text !important;
}
